import { gql } from '@apollo/client';

export const GET_VIEWER_INTERVIEW_SUMMARY = gql`
  query Viewer {
    viewer {
      ... on Viewer {
        id
        displayName
        dashboardSummary {
          recentInterview {
            createdAt
            job {
              title
              company
            }
            interview {
              id
              status
            }
            id
          }
          todoInterview {
            id
            createdAt
            job {
              id
              title
              company
              candidateApp
            }
            interview {
              id
              status
            }
          }
        }
        email
      }
      ... on RecordNotFound {
        message
      }
    }
  }
`;

export const GET_VIEWER_TODO_INTERVIEW = gql`
  query Viewer {
    viewer {
      ... on Viewer {
        id
        dashboardSummary {
          todoInterview {
            id
            createdAt
            job {
              title
              company
              candidateApp
            }
            interview {
              id
              status
            }
          }
        }
      }
      ... on RecordNotFound {
        message
      }
    }
  }
`;

export const GET_VIEWER_APPLICATIONS = gql`
  query Viewer($limit: Int, $skip: Int) {
    viewer {
      ... on Viewer {
        id
        applications(limit: $limit, skip: $skip) {
          nodes {
            createdAt
            job {
              id
              title
              company
              candidateApp
            }
            id
            interview {
              id
              status
            }
          }
        }
      }
      ... on RecordNotFound {
        message
      }
    }
  }
`;

export const GET_APPLICATION_DETAIL = gql`
  query Application($applicationId: String) {
    application(applicationId: $applicationId) {
      ... on Application {
        id
        professionalExperience
        createdAt
        completedAt
        qualified
        job {
          id
          title
          company
          candidateApp
          templateVersion
        }
        assessedMatch
        feedback {
          question
          answer
        }
        interview {
          id
          status
        }
        feedback {
          question
          answer
        }
      }
    }
  }
`;
